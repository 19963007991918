
















































import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/Base/BaseList/index.vue';
import { getCategoryChildren, ICategoryInfo, getProList } from '@/api/product';
import { IBaseProduct } from '@/api/type/base';
import { calcDeductCredit } from '@/utils/math';
@Component({
  name: '',
  components: { BaseList }
})
export default class extends Vue {
  categoryList = [] as ICategoryInfo[];

  get param() {
    return { catPid: this.catId };
  }

  get catId() {
    return +this.$route.params.id;
  }

  get api() {
    return getProList;
  }

  getDeductCredit(e: any) {
    return calcDeductCredit(e);
  }

  handleClickCategoryContent(item: ICategoryInfo) {
    this.$router.push({ name: 'Search', query: { catId: String(item.catId), name: item.name } });
  }

  handleClickBuy(data: IBaseProduct) {
    this.$router.push({ name: 'ProductDetail', params: { id: data.spuId.toString() } });
  }

  async getCategoryList() {
    const { payload } = await getCategoryChildren(this.catId);
    this.categoryList = payload.children;
    document.title = payload.name;
  }

  created() {
    this.getCategoryList();
  }
}
